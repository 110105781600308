<template>
    <div id="certifications" class="page">
        
        <div class="breadcrumbs">
            <router-link class="crumb-link" to="/profile">Profile</router-link> / <span>Certificates</span>
        </div>
        <h1 class="page-title">Certificates</h1>
        <p class="page-paragraph">
            In this section you may find a list of the most relevant courses and certifications I’ve completed lately,
            click the titles to check the actual certification from it's academy.
        </p>
        <div class="certificate-list">
            <!-- Certificate List -->
            <div class="certificate-link" v-for="cert in certs.cert" v-bind:key="cert.key">
                <h3 class="title-link">
                    <a :href=cert.titleLink >{{ cert.title }}</a>
                </h3>
                <p class="title-meta">{{cert.academy}} - {{cert.titleDate}}</p>
            </div>
        </div>

    </div>
</template>
<script>
import cert from '../assets/data/certificates.json';

export default {
    name: 'Certifications',
    data() {
        return {
            certs: cert
        }
    },
    mounted () {
        window.scrollTo(0, 0);
    }
}
</script>
<style lang="scss">
    @import '../assets/scss/utils.scss';
    .breadcrumbs {
        @include page-paragraph;
        .crumb-link {
            color: $foreground;
            @include from-center-underline-link;
        }
        span {
            font-weight: bold;
        }
    }
    .certificate-list {
        position: relative;
        width: 100%;
        min-height: 100px;
        margin-top: 29px;
        @media (min-width: $brk-screen) {
            margin-top: 38px;
        }
        &::before {
            content: '';
            display: block;
            width: 50%;
            height: 1px;
            background-color: $foreground;
            left: 0;
            top: 0;
            @media (min-width: $brk-screen) {
                width: 50%;
            }
        }
    }
    .certificate-link {
        margin: 41px 0;
        .title-link {
            @include content-title(0);
            margin: 10px 0;
        }
        .title-meta {
            @include content-sub-title;
            margin: 0;
        }
    }
</style>